/* Imports happen in Gulp */
var stylesheet = loadCSS( ThemeStyle, document.getElementById("loadcss"));
onloadCSS( stylesheet, function() {
  mainJs();
});

function menuResize(){
  var CurrentWidth = $(window).outerWidth();
  if(CurrentWidth < 991){
    $('.menu-item.init-move').prependTo('.offcanvas-menu--list-one');
    $('.offcanvas-menu--list-one .menu-item').addClass('added-item');
    $('.offcanvas-menu--list-one .menu-item').removeClass('init-move');
  }else{
    $('.menu-item.added-item').prependTo('.navigation-menu');
    $('.navigation-menu .menu-item').removeClass('added-item');
    $('.navigation-menu .menu-item').addClass('init-move');
  }
}

function mainJs() {

  WebFontConfig = {
    custom: {
      families: [
        'Font Awesome 5 Free',
        'Font Awesome 5 Brands',
        'Lato'
      ],

      urls: [LoadFonts]
    }
  };

  WebFont.load(WebFontConfig);


  menuResize();

  $(window).resize(function(){
    menuResize();
  });

  document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  });

  $(document).ready(function(){

    var totalcheckboxes = $('#Form_FilterForm .itemfilter-form--options .checkboxset').length;

    //Open all filters
    $('.open-all-filters').click(function(e){
      e.preventDefault();
      var inactiveText = $(this).data('inactive-text');
      var activeText = $(this).data('active-text');

      $(this).find('.inner-text').text(function(i, text){
           return text === inactiveText ? activeText : inactiveText;
      });

      $(this).find('.fa').toggleClass('fa-plus');
      $(this).find('.fa').toggleClass('fa-minus');

      if($(this).hasClass('.hide-all-filters')){
        $('.itemfilter-form--options .checkboxset').each(function(index){
          var collapseButton = $(this).find('label.left');
          var collapseArea = $(this).find('.middleColumn');
          var checkboxes = $(this).find("input:checkbox");

          collapseArea.collapse('hide');
          collapseButton.removeClass('active');
        });
      }else {
        $('.itemfilter-form--options .checkboxset').each(function(index){
          var collapseButton = $(this).find('label.left');
          var collapseArea = $(this).find('.middleColumn');
          var checkboxes = $(this).find("input:checkbox");

          collapseArea.collapse('show');
          collapseButton.addClass('active');
        });
      }

      $(this).toggleClass('.hide-all-filters');

    });

    //Delete selection of filters
    $('.delete-selection-filters').click(function(e){
        e.preventDefault();
        $('.itemfilter-form--options .checkboxset').each(function(index){
          var collapseButton = $(this).find('label.left');
          var collapseArea = $(this).find('.middleColumn');
          var checkboxes = $(this).find("input:checkbox");

          checkboxes.prop("checked", false);

          if (checkboxes.is(':checked')){
            collapseButton.addClass('has-options-checked');
          }else{
            collapseButton.removeClass('has-options-checked');
          }
        });
    });

    $('.itemfilter-form--options .checkboxset').each(function(index){
      var collapseButton = $(this).find('label.left');
      var collapseArea = $(this).find('.middleColumn');
      var checkboxes = $(this).find("input:checkbox");

      collapseArea.css('z-index', totalcheckboxes);
      totalcheckboxes--;

      if (checkboxes.is(':checked')){
        collapseButton.addClass('has-options-checked');
      }

      collapseArea.find('input').click(function(){
        if (checkboxes.is(':checked')){
          collapseButton.addClass('has-options-checked');
        }else{
          collapseButton.removeClass('has-options-checked');
        }
      });

      collapseButton.click(function(){
        collapseArea.collapse('toggle');
        collapseButton.toggleClass('active');
      });
    });

    if($('#Form_ContactFormulier').length != false){
      var PrivacyURL = $("#Form_ContactFormulier .data-privacyurl").data('privacyurl');
      $("#Form_ContactFormulier #Form_ContactFormulier_PrivacyCheckbox_Holder label").html( $("#Form_ContactFormulier #Form_ContactFormulier_PrivacyCheckbox_Holder label").html().replace(/privacyverklaring/g, "<a href="+PrivacyURL+" target='_blank'>privacyverklaring</a>") );
    }

    if($('#Form_NieuwsbriefFormulier').length != false){
      var PrivacyURL2 = $("#Form_NieuwsbriefFormulier .data-privacyurl").data('privacyurl');
      $("#Form_NieuwsbriefFormulier #Form_NieuwsbriefFormulier_PrivacyCheckbox_Holder label").html( $("#Form_NieuwsbriefFormulier #Form_NieuwsbriefFormulier_PrivacyCheckbox_Holder label").html().replace(/privacyverklaring/g, "<a href="+PrivacyURL2+" target='_blank'>privacyverklaring</a>") );
    }

    var imageGood = $('#Form_RatingForm .data-rating').data('rating-image-good');
    var imageNeutral = $('#Form_RatingForm .data-rating').data('rating-image-neutral');
    var imageBad = $('#Form_RatingForm .data-rating').data('rating-image-bad');

    var imageGoodGray = $('#Form_RatingForm .data-rating').data('rating-image-good-gray');
    var imageNeutralGray = $('#Form_RatingForm .data-rating').data('rating-image-neutral-gray');
    var imageBadGray = $('#Form_RatingForm .data-rating').data('rating-image-bad-gray');

    var textGood = $('#Form_RatingForm .data-rating').data('rating-text-good');
    var textNeutral = $('#Form_RatingForm .data-rating').data('rating-text-neutral');
    var textBad = $('#Form_RatingForm .data-rating').data('rating-text-bad');

    $('#Form_RatingForm .valGoed label').prepend('<img class="rating-good-image" src="'+imageGood+'" />');
    $('#Form_RatingForm .valNeutraal label').prepend('<img class="rating-neutral-image" src="'+imageNeutral+'" />');
    $('#Form_RatingForm .valSlecht label').prepend('<img class="rating-bad-image" src="'+imageBad+'" />');

    $('#Form_RatingForm_Rating_Holder').append('<h4 class="rating-form-statustitle"></h4>');

    $('#Form_RatingForm_Rating label[for="Form_RatingForm_Rating_Slecht"]').click(function(){
      $('#Form_RatingForm_Rating_Slecht').click();
    });
    $('#Form_RatingForm_Rating label[for="Form_RatingForm_Rating_Neutraal"]').click(function(){
      $('#Form_RatingForm_Rating_Neutraal').click();
    });
    $('#Form_RatingForm_Rating label[for="Form_RatingForm_Rating_Goed"]').click(function(){
      $('#Form_RatingForm_Rating_Goed').click();
    });

    $("#Form_RatingForm_Rating_Slecht, #Form_RatingForm_Rating_Goed, #Form_RatingForm_Rating_Neutraal", "#Form_RatingForm").change(function (){

      if($("#Form_RatingForm_Rating_Goed").is(":checked")) {
        $('.rating-form-statustitle').text(textGood);

        $('.rating-good-image').attr("src",imageGood);
        $('.rating-neutral-image').attr("src",imageNeutralGray);
        $('.rating-bad-image').attr("src",imageBadGray);
      }
      if($("#Form_RatingForm_Rating_Neutraal").is(":checked")) {
        $('.rating-form-statustitle').text(textNeutral);

        $('.rating-good-image').attr("src",imageGoodGray);
        $('.rating-neutral-image').attr("src",imageNeutral);
        $('.rating-bad-image').attr("src",imageBadGray);
      }
      if($("#Form_RatingForm_Rating_Slecht").is(":checked")) {
        $('.rating-form-statustitle').text(textBad);

        $('.rating-good-image').attr("src",imageGoodGray);
        $('.rating-neutral-image').attr("src",imageNeutralGray);
        $('.rating-bad-image').attr("src",imageBad);
      }
      $('.rating-form--feedbacktext').show();

    });


    if($('.form-error').length != false){
      $('.form-error').closest('.modal').modal('show');
    }

    $('.vragen-items').each(function(){
      $(this).find('.element-vragen--antwoord').on('shown.bs.collapse', function () {
        $(this).prev().find(".fa").removeClass("fa-plus").addClass("fa-minus");
      });

      $(this).find('.element-vragen--antwoord').on('hidden.bs.collapse', function () {
        $(this).prev().find(".fa").removeClass("fa-minus").addClass("fa-plus");
      });
    });


    //Menu
    $('.offcanvas-menu--open').on('click', function(e) {
       e.preventDefault();
       $($(this).data('menu-target')).addClass('open-menu');
    });

    $('.offcanvas-menu--close').on('click', function(e) {
       e.preventDefault();
       $($(this).data('menu-target')).removeClass('open-menu');
    });

    $(".animsition").animsition({
        inClass: 'fade-in',
        outClass: 'fade-out',
        inDuration: 1000,
        outDuration: 300,
        linkElement: '.animsition-link',
        loading: true,
        loadingParentElement: 'body',
        loadingClass: 'animsition-loading',
        timeout: false,
        timeoutCountdown: 5000,
        onLoadEvent: true,
        browser: [ 'animation-duration', '-webkit-animation-duration'],
        overlay : false,
        overlayClass : 'animsition-overlay-slide',
        overlayParentElement : 'body',
        transition: function(url){ window.location.href = url; }
    });

    if($('.banner--slider').length != false){
    if(!$('.banner--slider').hasClass('swiper-container-initialized')){
        var BannerSlider = new Swiper('.banner--slider', {
          slidesPerView: 1,
          navigation: {
            nextEl: '.slide-next',
            prevEl: '.slide-prev',
          }
        });
    }
    }

    if($('.element-news--slider').length != false){
    if(!$('.element-news--slider').hasClass('swiper-container-initialized')){
        var NewsSlider = new Swiper('.element-news--slider', {
          slidesPerView: 3,
          breakpoints: {
            991: {
              slidesPerView: 1
            }
          },
          navigation: {
            nextEl: '.slide-next',
            prevEl: '.slide-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable:true,
          }
        });
    }
    }

  });

}
