(function($) {
    var $pswp = $('.pswp')[0];
    var image = [];

    $('.gallery-images').each( function() {
        var $pic     = $(this),
            getItems = function() {
                var items = [];
                $pic.find('.element-gallery--popup').each(function() {
                    var $href   = $(this).attr('href'),
                        $caption = $(this).data('caption'),
                        $size   = $(this).data('size').split('x'),
                        $width  = $size[0],
                        $height = $size[1];

                    var item = {
                        src : $href,
                        title : $caption,
                        w   : $width,
                        h   : $height
                    }

                    items.push(item);
                });
                return items;
            }

        var items = getItems();

        $.each(items, function(index, value) {
            image[index]     = new Image();
            image[index].src = value['src'];
        });

        $pic.on('click', '.element-gallery--popup', function(event) {
            event.preventDefault();

            var $index = $(this).data('index');
            var options = {
                index: $index,
                bgOpacity: 0.7,
                showHideOpacity: true
            }

            var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
            lightBox.init();
        });
    });
})(jQuery);
